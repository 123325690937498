import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'

import { graphql } from 'gatsby'
import PageHeader from '../../components/PageHeader/PageHeader'
import BlogMain from '../../components/BlogMain/BlogMain'
import Footer from '../../components/Footer/Footer'
import HeaderProvider from '../../components/Header/HeaderProvider'
import SEO from '../../components/SEO'

const styles = {
  root: {},
}

const Guide = props => {
    const { classes, data } = props

    const modified = []
    data.datoCmsGuide._allUrlLocales.map(item => {
        modified.push({ 'locale': item.locale, 'value':  item.value })
    })
    console.log('modified', modified)
  return (
    <div className={classes.root}>
        <SEO
          seoMetaTags={data.datoCmsGuide.seoMetaTags.tags}
          actLang={props.pageContext.locale}
          localURLS={modified}
        />
      <HeaderProvider
        localURLS={data.datoCmsGuide._allUrlLocales}
        actLang={props.pageContext.locale}
        SEOtitle={data.datoCmsGuide.pageTitle}
        SEOdescription={data.datoCmsGuide.pageSubtitle}
        SEOimage={data.datoCmsGuide.headerImage.fluid.src}
        SEOpathname={data.datoCmsGuide.url}

      />
      <PageHeader
        leftHeader
        image={data.datoCmsGuide.headerImage.fluid}
        title={data.datoCmsGuide.pageTitle}
        subtitle={data.datoCmsGuide.pageSubtitle}
        alt={data.datoCmsGuide.headerImage.alt}
      />
      <BlogMain
        allDatoCmsArticle={data.allDatoCmsArticle}
        actLang={props.pageContext.locale}
      />
      <Footer
        actLang={props.pageContext.locale}
      />
    </div>
  )
}

export default withStyles(styles)(Guide)

export const query = graphql`
    query($locale: String!) {
        datoCmsGuide(locale:{eq:$locale}){
            seoMetaTags{
                tags
            }
            _allUrlLocales{locale value}
            url
            pageTitle
            pageSubtitle
            headerImage{
                alt
                fluid(maxWidth:1800){
                    ...GatsbyDatoCmsFluid_noBase64
                }
            }
        }
        allDatoCmsArticle(
            sort:{
                fields:meta___updatedAt
                order:DESC
            }
            filter:{
                meta:{status:{eq:"published"}  }
                locale:{eq:$locale}
            }
        ){
            nodes{
                categories{ category url}
                url
                title
                meta{
                    updatedAt(formatString: "MM-DD-YYYY")
                }
                summary
                featuredImage{
                    alt
                    fluid(maxWidth:705){
                        src
                        ...GatsbyDatoCmsFluid_noBase64
                    }
                }
            }
        }
    }
`