import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import blogMainStyle from './blogMainStyle.jsx'
import Section from '../Section/Section'
import StandardArticle from '../StandardArticle/StandardArticle'

const BlogMain = props => {


  const { classes, allDatoCmsArticle } = props
  return (
    <Section paddingTop={0}>

      {allDatoCmsArticle.nodes.map(article => {
        return (
          <StandardArticle
            article={article}
            actLang={props.actLang}
          />
        )
      })}

      {/*<div className={classes.sectionWrapper}>*/}
      {/*  {data.allDatoCmsArticle.nodes.slice(1, 4).map(article => {*/}
      {/*    return (*/}
      {/*      <ReactRevealFadeIn>*/}
      {/*        <div className={classes.smallAricleWrapper}>*/}
      {/*          <Link to={'guide/' + article.url}>*/}
      {/*            <Img*/}
      {/*              className={classNames(classes.smallImage, classes.imageDarkenOnHover)}*/}

      {/*              fluid={article.featuredImage.fluid}*/}
      {/*              backgroundColor={'#f2f2f2'}*/}
      {/*              objectFit="contain"*/}
      {/*              objectPosition="50% 50%"*/}
      {/*            />*/}
      {/*          </Link>*/}
      {/*          <div className={classes.smallArticleContent}>*/}
      {/*            <Link to={'guide/' + article.url}>*/}
      {/*              <h4 className={classNames(classes.smallTitle, classes.smtitle)}>*/}
      {/*                {article.title}*/}
      {/*              </h4>*/}
      {/*            </Link>*/}
      {/*            <Link to={'guide/' + article.url}>*/}
      {/*              <p className={classes.smallDate}>{article.meta.updatedAt}</p>*/}
      {/*            </Link>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </ReactRevealFadeIn>*/}
      {/*    )*/}
      {/*  })}*/}


      {/*</div>*/}

    </Section>
  )

}

export default withStyles(blogMainStyle)(BlogMain)

