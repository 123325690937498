import React from 'react'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'

import Img from 'gatsby-image'
import { Link } from 'gatsby'

import standardArticleStyle from './standardArticleStyle.jsx'

import classNames from 'classnames'
import ReactRevealFadeIn from '../ReactRevealFadeIn/ReactRevealFadeIn'


const StandardArticle = props => {


  const { classes, article } = props


  return (
    <ReactRevealFadeIn>
      <div className={classes.mainWrapper}>
        <div className={classes.subWrapper}>
          <div className={classes.firstWrapper}>
            <Link to={props.actLang === "de" ? 'de/guide/' + article.url :'guide/' + article.url} style={{ width: '100%' }}>
              <Img
                className={classNames(classes.bigImage, classes.imageDarkenOnHover)}

                fluid={article.featuredImage.fluid}
                alt={article.featuredImage.alt}
                backgroundColor={'#f2f2f2'}
                objectFit="contain"
                objectPosition="50% 50%"
              />
            </Link>

          </div>
          <div className={classes.secondWrapper}>
            <div className={classes.smallArticleContent}>

              <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 15 }}>
                {article.categories ? article.categories.map((cat, index) => {
                  if (index === article.categories.length - 1) {
                    return (
                      <Link to={props.actLang === "de" ? 'de/guide/kategorie/' + cat.url :'guide/category/' + cat.url }>
                        <p className={classNames(classes.P, classes.categoryP)}>
                          {cat.category}
                        </p>
                      </Link>
                    )
                  } else {
                    return (
                      <Link to={props.actLang === "de" ? 'de/guide/kategorie/' + cat.url :'guide/category/' + cat.url }>
                        <p className={classNames(classes.P, classes.categoryP)} style={{ paddingRight: 10 }}>
                          {cat.category},
                        </p>
                      </Link>
                    )
                  }


                }) : null}
              </div>
              <Link to={props.actLang === "de" ? 'de/guide/' + article.url :'guide/' + article.url }>
                <h4 className={classes.smallTitle}>
                  {article.title}
                </h4>
                <p className={classes.P}>
                  {article.summary ?
                    article.summary.length > 140 ? article.summary.slice(0, 140) + '...' : article.summary
                    : null}

                </p>
              </Link>

              <Link to={props.actLang === "de" ? 'de/guide/' + article.url :'guide/' + article.url}>
                <p className={classes.smallDate}>{article.meta.updatedAt}</p>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </ReactRevealFadeIn>
  )

}

export default withStyles(standardArticleStyle)(StandardArticle)
